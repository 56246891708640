import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// cv以下代码解决路由地址重复的报错问题(一劳永逸)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/query',
    name: 'Query',
    component: () => import('@/views/Query.vue')
  },
  {
    path: '/plar',
    name: 'Plar',
    component: () => import('@/views/Plar.vue')
  },
  {
    path: '/plarDetail',
    name: 'PlarDetail',
    component: () => import('@/views/PlarDetail.vue')
  },
  {
    path: '/introduce',
    name: 'Introduce',
    component: () => import('@/views/Introduce.vue')
  },
  {
    path: '/payRecord',
    name: 'PayRecord',
    component: () => import('@/views/PayRecord.vue')
  },
  {
    path: '/expendRecord',
    name: 'ExpendRecord',
    component: () => import('@/views/ExpendRecord.vue')
  },
  {
    path: '/housePayment',
    name: 'HousePayment',
    component: () => import('@/views/HousePayment')
  },
  {
    path: '/housing',
    name: 'Housing',
    component: () => import('@/views/Housing.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
