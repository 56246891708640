import axios from "axios";
export default{
  getIdcard(){
    const url="http://localhost:8989/api/ReadMsg";
    var idCard="";
  $.ajax({
      url:url,
      type:'get',
      dataType:'json',
      async:false,
      success:function (res) {
           if(res.code==0){
             idCard=res.cardno
           }
      },
      error:function () {

      }
    })
    return idCard;
  },
  getphoto(){
    const url="http://localhost:8989/api/ReadMsg";
    var photo="";
  $.ajax({
      url:url,
      type:'get',
      dataType:'json',
      async:false,
      success:function (res) {
           if(res.code==0){
             photo=res.photobase64
           }
      },
      error:function () {

      }
    })
    return photo;
  }
}
