import Vue from 'vue'
import store from 'store'
import App from './App.vue'
import router from './router'
import qs from 'qs'
import axios from './httpConfig/http.js'
import jquery from 'jquery'
import baseconfig  from './assets/css/public.css'
import Config  from './assets/js/common'
import utils  from './assets/js/Util'
import moment from 'moment'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI)


Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.prototype.$ = jquery
Vue.prototype.$Config=Config;
Vue.prototype.$utils = utils;
Vue.prototype.$moment = moment
// Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
