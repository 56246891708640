import  axios from 'axios'
import router from '../router'
import ElementUI from 'element-ui'

// axios默认配置
// axios.defaults.timeout = 10000;   // 超时时间
// axios.defaults.baseURL = '/api';  // 默认地址
// import cookie from '../../static/js/cookie.js'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 路由请求拦截
axios.interceptors.request.use(
  config => {
    //判断是否存在ticket，如果存在的话，则每个http header都加上ticket
    let token = window.sessionStorage.getItem('token');
    if(token){
      config.headers.Authorization = token
    }
    return config;
  },
  error => {
    return Promise.reject(error.response);
  });

// 路由响应拦截
// http response 拦截器
axios.interceptors.response.use(
  response => {
    let token = response.headers.authorization;
    if (token) { // 判断token是否存在，如果存在说明需要更新token
      sessionStorage.setItem('token', token)
    }
    return response;
  },
  error => {
    // if (error.response.data.code == 401) {
    //   ElementUI.Message.closeAll();
    //   ElementUI.Message.error(error.response.data.msg);
    //   sessionStorage.removeItem("token");
    //   // router.push({
    //   //   name:'Login'
    //   // })
    // }
    return Promise.reject(error.response)   // 返回接口返回的错误信息
  });
export default axios;
