import axios from "axios";

const Config = class {
    constructor(props) {
        this.base_server = 'https://dx-ssmapi.gshmf.com';
        this.page_server = 'http://127.0.0.1:8066';
        this.tableName = 'easyweb'; // 存储表名
        this.autoRender = false; // 窗口大小改变后是否自动重新渲染表格，解决layui数据表格非响应式的问题，目前实现的还不是很好，暂时关闭该功能
        this.pageTabs = true; // 是否开启多标签
    }
}


export default new Config();
